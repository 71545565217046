<template>
  <div class="report rectBox boxb">
    <div class="labelHdader disfr jsb">
      <span>数据范围</span
      ><!-- 投放报表 -->
      <div class="headerOperation disfr ac">
        <div class="Oper disfr ac" @click="refresh">
          <img
            src="https://newmarket4.tt286.com:443/droi/images/11a5f1b070214b7e8c0215ae26ea1ef5.png"
            alt=""
          />
          <span class="colorB">重置</span>
        </div>
        <div class="Oper disfr ac" @click="downTable">
          <img
            src="https://newmarket4.tt286.com:443/droi/images/5329f4a8c78649c3b257202a523e87dc.png"
            alt=""
          />
          <span class="colorB">下载</span>
        </div>
      </div>
    </div>
    <el-form class="searchForm" size="small">
      <el-row>
        <el-col :span="24" class="disfr ac">
          <el-form-item>
            <el-select
              v-model="searchForm.dataTime"
              class="dataTime"
              @change="changeDataTime"
              :disabled="disabledWeek"
            >
              <el-option
                v-for="item in dataTimes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="searchForm.date"
              @change="changeDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchForm.envType" class="dataType">
              <el-option
                v-for="item in environmentList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.userIdList"
              @change="changeMultiple($event, 'userIdList')"
              multiple
              clearable
              collapse-tags
              class="app"
              filterable
              placeholder="投放者"
            >
              <el-option
                v-for="item in devloperList"
                :key="item.userId"
                :label="item.companyName"
                :value="item.userId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.appIdList"
              @change="changeMultiple($event, 'appIdList')"
              multiple
              clearable
              collapse-tags
              class="app"
              filterable
              placeholder="应用"
            >
              <el-option
                v-for="(item, index) in appList"
                :key="index"
                :label="item.appName"
                :value="item.appName1"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.businessUserId"
              @change="changeMultiple($event, 'businessUserId')"
              multiple
              clearable
              collapse-tags
              class="task"
              filterable
              placeholder="商务"
            >
              <el-option
                v-for="(item, index) in businessNameList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="disfr ac flexWrap">
          <el-form-item>
            <el-select
              v-model="searchForm.reportType"
              class="dataType"
              @change="changeReportType"
            >
              <el-option
                v-for="item in reportTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.taskIdList"
              @change="changeMultiple($event, 'taskIdList')"
              multiple
              clearable
              collapse-tags
              class="task"
              filterable
              placeholder="推广计划"
            >
              <el-option
                v-for="(item, index) in taskList"
                :key="index"
                :label="item.taskName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.ployIdList"
              @change="changeMultiple($event, 'ployIdList')"
              multiple
              clearable
              collapse-tags
              class="task"
              filterable
              placeholder="推广组"
            >
              <el-option
                v-for="(item, index) in strategyList"
                :key="index"
                :label="item.ployName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.ideaIdList"
              @change="changeMultiple($event, 'ideaIdList')"
              multiple
              clearable
              collapse-tags
              class="task"
              filterable
              placeholder="创意"
            >
              <el-option
                v-for="(item, index) in originalityList"
                :key="index"
                :label="item.ideaName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchForm.costType" class="dataType">
              <el-option
                v-for="item in collaborateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.flowScenes"
              class="dataType"
              @change="changeSource"
              placeholder="投放网络"
            >
              <el-option
                v-for="item in sourceTypeList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.recomTypeList"
              @change="changeMultiple($event, 'recomTypeList')"
              class="task"
              multiple
              clearable
              collapse-tags
              placeholder="营销目标"
            >
              <el-option
                v-for="item in recomList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.countryCode"
              @change="changeMultiple($event, 'countryCode')"
              class="task"
              placeholder="国家定向"
              multiple
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="item in languageList"
                :key="item.countryCode"
                :label="item.countryName"
                :value="item.countryCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item>
            <el-select
              v-model="searchForm.recomType"
              @change="changeMultiple($event, 'recomType')"
              multiple
              clearable
              collapse-tags
              class="task"
              filterable
              placeholder="推荐版位"
            >
              <el-option
                v-for="(item, index) in positionList"
                :key="index"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item style="padding: 10px 0">
            <el-button type="primary" class="formItem" @click="searchFn"
              >查询</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="taskStatistics mb20" v-show="isShowCPAEcharts">
      <div class="rectBox disfr taskBox boxb">
        <div
          class="flex1 fc jc task tc"
          v-for="(item, index) in taskHeaderList"
          :key="index"
        >
          <div class="moneyTxt fz26">
            <span v-if="index == 2 || index == 4"
              >{{ item.value | numChange }}%</span
            >
            <span v-else>{{ item.value }}</span>
          </div>
          <div class="label">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="chart" ref="chart" v-show="isShowCPAEcharts"></div>

    <div class="seriesBox disfr ac jsb">
      <div class="disfr ac">
        <span>筛选维度：</span>
        <el-checkbox-group v-model="checkSeries" @change="changeSeries">
          <template v-for="(item, index) in seriesList">
            <!-- v-if="item.label != '应用ID'" -->
            <el-checkbox
              v-if="
                ![
                  '应用ID',
                  '计划预算',
                  '投放网络',
                  '营销目标',
                  '投放时段',
                  '出价模式',
                ].includes(item.label)
              "
              :key="index"
              :label="item.key"
            >
              {{ item.label }}
            </el-checkbox>
          </template>
        </el-checkbox-group>
      </div>
    </div>

    <PageTable
      id="statisticsTable"
      style="width: 100%"
      class="pageTable"
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
      <template #costType="{ row }">
        <span>{{ row.costType | typeLabel(collaborateList) }}</span>
      </template>
      <template #pt="{ row }">
        <span>{{
          searchForm.reportType == 3
            ? row.pt + "_" + row.hourKey + "点"
            : row.pt
        }}</span>
      </template>
      <!-- <template #recomType="{ row }">
        <span>{{ row.recomType | typeDesc(sellPositionList) }}</span>
      </template> -->
      <template #downloadsRate="{ row }">
        <span v-if="row.downloadsRate !== undefined"
          >{{ row.downloadsRate | numChange }}%</span
        >
      </template>
      <template #clicksRate="{ row }">
        <span v-if="row.clicksRate !== undefined"
          >{{ row.clicksRate | numChange }}%</span
        >
      </template>
      <template #averageAmount="{ row }">
        <span v-if="row.averageAmount !== undefined">{{
          row.averageAmount | numfixed
        }}</span>
      </template>
      <template #averageShowAmount="{ row }">
        <span v-if="row.averageShowAmount !== undefined">{{
          row.averageShowAmount | numfixed
        }}</span>
      </template>
      <template #flowScenes="{ row }">
        <span>{{ row.flowScenes | typeDesc(sourceTypeList) }}</span>
      </template>
      <template #recomType="{ row }">
        <span>{{ row.recomType | typeDesc(sourceTypeAllList) }}</span>
      </template>
      <template #startDate="{ row }">
        <span>{{ row.startDate }}--{{ row.endDate }}</span>
      </template>
    </PageTable>
  </div>
</template>

<script>
import {
  costTypeList,
  // MAXSIZE,
  sellPositionList,
  environmentList,
  collaborateList,
  sourceTypeList,
  recomLabelTypeList,
  recomLabelTypeList1,
  recomLabelTypeList2,
} from "@/common/constant/constant.js";
import exportExcel from "@/common/js/excelPort.js";
// import { getDaysBetweenDay } from "@/common/utils/utils.js";
const dataEcharts = {
  myCharts: null,
};
import {
  getTaskReportSum, // 投放报表
  queryTaskReportList, // 投放报表
  getAllAppStatementList,
  // getDeveloperUserList,
  getTaskPloyNameList,
  getideaListList,
  getIdeaNameList,
  getPromoteTaskNameList,
  getBusinessList,
  cmsCountryAll,
} from "@/common/js/api";
import { getDeveloperUserList } from "@/views/business/developer/putinDeveloperList/indexServe.js";
import PageTable from "@/components/PageTable.vue";
// import { getLanguageList } from "@/views/business/appManagement/putinApkManagement/indexServe.js";
export default {
  name: "statisticsReport",
  components: { PageTable },
  data() {
    return {
      costTypeList,
      sellPositionList,
      environmentList,
      collaborateList,
      sourceTypeList,
      recomLabelTypeList,
      recomLabelTypeList1,
      // recomList: recomLabelTypeList,
      recomList: recomLabelTypeList2,
      sourceTypeAllList: recomLabelTypeList2,
      // sourceTypeAllList: [...recomLabelTypeList, ...recomLabelTypeList2],
      isShowCPAEcharts: true,
      searchForm: {
        dataTime: 1,
        date: [],
        reportType: 2,
        envType: 0,
        costType: 1,
        flowScenes: "",
        recomTypeList: [""],
        countryCode: [""],
        taskIdList: [""],
        appIdList: [""],
        userIdList: [""],
        ployIdList: [""],
        ideaIdList: [""],
        recomType: [""],
        businessUserId: [""],
      },
      value: [],
      dataTimes: [
        {
          label: "本周",
          value: 1,
          fn: "nowWeek",
        },
        {
          label: "本月",
          value: 2,
          fn: "nowMonth",
        },
        {
          label: "上月",
          value: 3,
          fn: "lastMonth",
        },
      ],
      disabledWeek: false,
      reportTypes: [
        {
          label: "合计",
          value: 1,
        },
        {
          label: "分天",
          value: 2,
        },
        // {
        //   label: "小时",
        //   value: 3,
        // },
      ],
      // appList: [{ appName: "全部应用", appId: "" }],
      appList: [{ appName: "全部应用", appName1: "" }],
      taskList: [
        {
          taskName: "全部推广计划",
          id: "",
        },
      ],
      devloperList: [
        {
          companyName: "全部投放者",
          userId: "",
        },
      ],
      taskHeaderList: [
        // {
        //   name: "总展示(次)",
        //   value: 0,
        //   key: "sumShows",
        // },
        // {
        //   name: "总点击(次)",
        //   value: 0,
        //   key: "sumClicks",
        // },
        // {
        //   name: "总点击率",
        //   value: "0",
        //   key: "sumClicksRate",
        // },
        {
          name: "计费下载(次)",
          value: 0,
          key: "computeNumsDown",
        },
        // {
        //   name: "总下载率",
        //   value: 0,
        //   key: "sumDownloadsRate",
        // },
        {
          name: "总消耗($)",
          value: 0,
          key: "consumeTotal",
        },
      ],
      strategyList: [
        {
          ployName: "全部推广组",
          id: "",
        },
      ],
      originalityList: [
        {
          ideaName: "全部创意",
          id: "",
        },
      ],
      positionList: [
        {
          value: "全部推荐版位",
          key: "",
        },
        ...sellPositionList,
      ],
      businessNameList: [
        {
          name: "全部商务",
          id: "",
        },
      ],
      chartOptions: {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [
            { name: "消耗($)" },
            { name: "下载均价($)" },
            // { name: "展示量" },
            // { name: "下载率" },
            // { name: "点击率" },
          ],
          selected: {
            "消耗($)": true,
            "下载均价($)": false,
            // 展示量: false,
            // 下载率: false,
            // 点击率: false,
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {},
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: [
          {
            name: "计费下载",
            type: "value",
          },
          {
            name: "消耗($)",
            type: "value",
          },
        ],
        series: [
          {
            name: "计费下载",
            type: "line",
            // stack: "Total",
            yAxisIndex: 0,
            data: [],
            dataKey: "computeNumsDown",
          },
          {
            name: "消耗($)",
            type: "line",
            // stack: "Total",
            yAxisIndex: 1,
            data: [],
            dataKey: "consumeTotal",
          },
          {
            name: "下载均价($)",
            type: "line",
            // stack: "Total",
            yAxisIndex: 1,
            data: [],
            dataKey: "averageAmount",
          },
          // {
          //   name: "展示量",
          //   type: "line",
          //   stack: "Total",
          //   yAxisIndex: 1,
          //   data: [],
          //   dataKey: "Shows",
          // },
          // {
          //   name: "下载率",
          //   type: "line",
          //   stack: "Total",
          //   yAxisIndex: 1,
          //   data: [],
          //   dataKey: "downloadsRate",
          // },
          // {
          //   name: "点击率",
          //   type: "line",
          //   stack: "Total",
          //   yAxisIndex: 1,
          //   data: [],
          //   dataKey: "clicksRate",
          // },
        ],
      },
      seriesList: [
        // 筛选数据列表
        {
          label: "商务",
          key: "isBusinessName",
          showSeries: {
            key: "businessName",
            label: "商务",
          },
        },
        {
          label: "投放者",
          key: "isUserShow",
          showSeries: {
            key: "companyName",
            label: "投放者",
          },
        },
        {
          label: "应用ID",
          key: "isAppShow",
          showSeries: {
            key: "appId",
            label: "应用ID",
          },
        },
        {
          label: "应用",
          key: "isAppShow",
          showSeries: {
            key: "title",
            label: "应用",
          },
        },
        {
          label: "出价模式",
          key: "isAppShow",
          showSeries: {
            slot: "costType",
            label: "出价模式",
          },
        },
        {
          label: "推广计划",
          key: "isPlanShow",
          showSeries: {
            key: "planName",
            label: "计划名称",
          },
        },
        {
          label: "计划预算",
          key: "isPlanShow",
          showSeries: {
            key: "budgetAmount",
            label: "计划预算",
          },
        },
        {
          label: "推广组",
          key: "isGroupShow",
          showSeries: {
            key: "groupName",
            label: "推广组",
          },
        },
        {
          label: "投放网络",
          key: "isGroupShow",
          showSeries: {
            slot: "flowScenes",
            label: "投放网络",
          },
        },
        {
          label: "营销目标",
          key: "isGroupShow",
          showSeries: {
            slot: "recomType",
            label: "营销目标",
          },
        },
        {
          label: "投放时段",
          key: "isGroupShow",
          showSeries: {
            slot: "startDate",
            label: "投放时段",
          },
        },
        {
          label: "创意",
          key: "isIdeaShow",
          showSeries: {
            key: "ideaName",
            label: "创意",
          },
        },
        // {
        //   label: "版位",
        //   key: "isRecomType",
        //   showSeries: {
        //     slot: "recomType",
        //     label: "版位",
        //   },
        // },
        // {
        //   label: "搜索词",
        //   key: "isKeyWord",
        //   showSeries: {
        //     key: "keyWord",
        //     label: "搜索词",
        //   },
        // },
        // {
        //   label: "商务",
        //   key: "isSource",
        //   showSeries: {
        //     key: "source",
        //     label: "商务",
        //   },
        // },
      ],
      initColumns: [
        // { key: "taskId", label: "任务ID" },
        // { key: "taskName", label: "任务名称" },
        // { key: "appName", label: "应用名称" },

        // { key: "realExpendAmount", label: "实际消耗金额（$）" },

        // { key: "shows", label: "展示量" },
        // { key: "remark", label: "展示率" },
        // { key: "clicks", label: "点击量" },
        // { slot: "clicksRate", label: "点击率" },
        { key: "shows", label: "曝光" },
        { key: "clicks", label: "点击" },
        // { key: "shows12", label: "下载量级数据" },
        // { key: "shows13", label: "安装UV" },
        // { key: "shows14", label: "包名" },
        // { key: "shows15", label: "APKID" },
        { key: "computeNumsDown", label: "计费下载" },
        // { slot: "downloadsRate", label: "下载率" },
        // { key: "computeNums", label: "计费下载" },
        { slot: "averageAmount", label: "推广均价（$)" },
        { slot: "averageShowAmount", label: "曝光均价（$)" },
        { key: "consumeTotal", label: "消耗（$）" },
        { key: "consumeAmount", label: "充值消耗（$)" },
        { key: "consumeLaunch", label: "激励消耗（$)" },
        // { key: "consumeRebate", label: "返利消耗（$)" },
      ],
      initCPDColumns: [
        { key: "shows", label: "曝光" },
        { key: "clicks", label: "点击" },
        { key: "downloads", label: "下载量" },
      ],
      columns: [],
      tableData: {
        list: [],
        size: 10,
        page: 1,
        total: 0,
      },
      languageList: [
        {
          countryName: "全部地区",
          countryCode: "",
        },
      ],
      chartInstance: null,
      checkSeries: [],
      formKey: [
        "userIdList",
        "recomTypeList",
        "appIdList",
        "taskIdList",
        "ployIdList",
        "ideaIdList",
        "recomType",
        "businessUserId",
        "countryCode",
      ],
      listKey: [
        "getDevList",
        "getAppList",
        "getTaskList",
        "getTaskPloyNameList",
        "getideaListList",
        // "getIdeaNameList",
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime());
              end.setTime(start.getTime());
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(start.getDate() - 1); // 开始日期是昨天
              end.setDate(end.getDate() - 1); // 结束日期是昨天
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "前三天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(start.getDate() - 3); // 开始日期是昨天的前两天
              end.setDate(end.getDate() - 1); // 结束日期是昨天
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  async mounted() {
    getBusinessList({}).then((res) => {
      if (res && res.code === 0) {
        this.businessNameList = [
          {
            name: "全部商务",
            id: "",
          },
        ].concat(res.data);
      }
    });
    this.getLanguageList();
    await this.init();
    dataEcharts.myCharts.on("legendselectchanged", (params) => {
      let { name } = params;
      let { legend, yAxis } = this.chartOptions;
      yAxis[1].name = name;
      for (let i in legend.selected) {
        legend.selected[i] = false;
        if (i === name) {
          legend.selected[i] = true;
        }
      }
      this.chartOptions.legend = legend;
      this.chartOptions.yAxis = yAxis;
      this.initCharts();
    });
  },
  methods: {
    async initployAndIdea() {
      this.searchForm.ployIdList = [""];
      this.searchForm.ideaIdList = [""];
      this.searchForm.recomType = [""];
      this.searchForm.businessUserId = [""];
      // await Promise.all([this.getTaskPloyNameList(), this.getIdeaNameList()]);
      // await Promise.all([this.getTaskPloyNameList()]);
    },
    changeSeries() {
      this.getReportList();
    },
    async downTable() {
      let arr = [this.columns.map((a) => a.label)];
      let params = this.filterParams();
      this.seriesList.forEach((a) => {
        params[a.key] = 0;
        if (this.checkSeries.find((b) => b === a.key)) {
          params[a.key] = 1;
        }
      });
      let obj = {
        ...params,
        // pageSize: MAXSIZE,
        pageSize: -1,
      };
      const res = await queryTaskReportList(obj);
      // const reportList = res.data.reportList;
      const reportList = res.data.list || [];
      reportList.forEach((a) => {
        a.downloadsRate =
          this.$options.filters.numChange(a.downloadsRate) + "%";
        a.clicksRate = this.$options.filters.numChange(a.clicksRate) + "%";
        a.averageAmount = this.$options.filters.numfixed(a.averageAmount);
        a.averageShowAmount = this.$options.filters.numfixed(
          a.averageShowAmount
        );
        a.flowScenes = this.$options.filters.typeDesc(
          a.flowScenes,
          this.sourceTypeList
        );
        a.costType = this.$options.filters.typeLabel(
          a.costType,
          collaborateList
        );
        a.recomType = this.$options.filters.typeDesc(
          a.recomType,
          this.sourceTypeAllList
        );
        a.startDate = a.startDate ? a.startDate + "--" + a.endDate : "";
        // a.recomType = this.$options.filters.typeDesc(
        //   a.recomType,
        //   sellPositionList
        // );
        a.pt = params.reportType == 3 ? a.pt + "_" + a.hourKey + "点" : a.pt;
        let child = [];
        this.columns.forEach((b) => {
          const key = b.key || b.slot;
          child.push(a[key]);
        });
        arr.push(child);
      });
      exportExcel(arr, "投放报表");
    },
    handleChangePage({ page, size }) {
      //切换页面
      this.getReportList({ page: page, size: size });
    },
    changeMultiple(sec, _type) {
      //多选控制
      if (sec.slice(-1)[0] === "" && sec.length > 0) {
        this.searchForm[_type] = [""];
        return;
      }
      if (sec[0] === "" && sec.length > 0) {
        this.searchForm[_type].shift();
      }
      if (_type == "recomTypeList") {
        return;
      }
      let index = this.formKey.findIndex((a) => a === _type);
      this.formKey.forEach((a, ai) => {
        if (ai > index) {
          this.searchForm[a] = [""];
          this[this.listKey[ai]]();
        }
      });
    },
    changeReportType() {
      // console.log(val);
      // if (val == 3) {
      //   this.searchForm.dataTime = [];
      //   this.disabledWeek = true;
      // } else {
      //   this.disabledWeek = false;
      // }
    },
    async init() {
      this.searchForm.date = this.nowWeek();
      dataEcharts.myCharts = this.echarts.init(this.$refs.chart);
      await Promise.all([
        this.getAppList(),
        this.getDevList(),
        this.getTaskList(),
        this.getTaskPloyNameList(),
        this.getideaListList(),
        // this.getIdeaNameList(),
      ]);
      await Promise.all([this.getReportList(), this.getReportStatistics()]);
      this.initCharts();
    },
    initCharts() {
      let initOption = JSON.parse(JSON.stringify(this.chartOptions));
      dataEcharts.myCharts.setOption(initOption);
    },
    filterParamsList(key, listKey, listChildKey) {
      //列表联动 父级列表
      let list = this.searchForm[key].filter((a) => a !== "");
      if (list.length) {
        return list;
      }
      list = this[listKey]
        .filter((a) => a[listChildKey] !== "")
        .map((a) => a[listChildKey]);
      if (key == "userIdList") {
        return [];
      }
      return list;
    },
    async getIdeaNameList() {
      let res = await getIdeaNameList({
        ployIds: this.filterParamsList("ployIdList", "strategyList", "id"),
      });
      if (res.code !== 0) return;
    },
    // 推广组列表
    async getTaskPloyNameList() {
      let res = await getTaskPloyNameList({
        planIdList: this.filterParamsList("taskIdList", "taskList", "id"),
      });
      if (res.code !== 0) return;
      let curAppNameList = [];
      let ListAppName = res.data || [];
      for (let Y = 0; Y < ListAppName.length; Y++) {
        curAppNameList.push({
          ...ListAppName[Y],
          ployName: ListAppName[Y].groupName,
          id: ListAppName[Y].id,
        });
      }

      this.strategyList = [
        {
          ployName: "全部推广组",
          id: "",
        },
      ].concat(curAppNameList);
    },
    // 创意列表
    async getideaListList() {
      let res = await getideaListList({
        groupIdList: this.filterParamsList("ployIdList", "strategyList", "id"),
      });
      if (res.code !== 0) return;
      let curAppNameList = [];
      let ListAppName = res.data || [];
      for (let Y = 0; Y < ListAppName.length; Y++) {
        curAppNameList.push({
          ...ListAppName[Y],
          ideaName: ListAppName[Y].ideaName,
          id: ListAppName[Y].id,
        });
      }

      this.originalityList = [
        {
          ideaName: "全部创意",
          id: "",
        },
      ].concat(curAppNameList);
    },
    async getAppList() {
      let res = await getAllAppStatementList({
        userId: this.filterParamsList(
          "userIdList",
          "devloperList",
          "userId"
        ).join(),
      });
      if (res.code !== 0) return;
      // this.appList = [
      //   {
      //     appName: "全部应用",
      //     appId: "",
      //   },
      // ].concat(res.data);
      let curAppNameList = [];
      let ListAppName = res.data.list;
      for (let Y = 0; Y < ListAppName.length; Y++) {
        curAppNameList.push({
          ...ListAppName[Y],
          appName: ListAppName[Y].title,
          appName1: ListAppName[Y].appId,
        });
      }
      this.appList = [
        {
          appName: "全部应用",
          appName1: "",
          // appId: "",
        },
      ].concat(curAppNameList);
    },
    async getTaskList() {
      let res = await getPromoteTaskNameList({
        appIds: this.filterParamsList("appIdList", "appList", "appName1"),
        isName: 1,
      });
      if (res.code !== 0) return;
      // let data = res.data || [];
      // this.taskList = [
      //   {
      //     taskName: "全部推广计划",
      //     id: "",
      //   },
      // ].concat(data);
      let curAppNameList = [];
      let ListAppName = res.data || [];
      for (let Y = 0; Y < ListAppName.length; Y++) {
        curAppNameList.push({
          ...ListAppName[Y],
          taskName: ListAppName[Y].planName,
          id: ListAppName[Y].id,
        });
      }

      this.taskList = [
        {
          taskName: "全部推广计划",
          id: "",
        },
      ].concat(curAppNameList);
    },
    async getDevList() {
      let res = await getDeveloperUserList({
        backendType: 3,
        identityStatus: 3,
        size: 30000,
      });
      if (res.code !== 0) return;
      let arr = res.data.list || [];
      const newArray = arr
        .filter((item) => item.companyName) // 过滤出 companyName 非空的对象
        .map((item) => ({
          userId: item.id, // 将 userId 赋值给 id
          companyName: item.companyName, // 保留 companyName
        }));
      this.devloperList = [
        {
          companyName: "全部投放者",
          userId: "",
        },
      ].concat(newArray);
    },
    async getReportList(args) {
      let params = this.filterParams();
      params = {
        ...params,
        page: 1,
        size: 10,
        ...args,
      };
      if (params.reportType == 3 && (!params.endTime || !params.startTime)) {
        this.$message.error(`按照小时格式查询时需选择时间段`);
        return;
      }
      this.seriesList.forEach((a) => {
        params[a.key] = 0;
        if (this.checkSeries.find((b) => b === a.key)) {
          params[a.key] = 1;
        }
      });
      const res = await queryTaskReportList(params);
      if (res.code !== 0) return;
      const data = res.data;
      data.reportList = data.list || [];
      this.tableData.list = data.reportList;
      this.tableData.total = data.total;
      this.tableData.page = params.page;
      this.tableData.size = params.size;
      //选中维度后 列表显示过滤
      let arr = [];
      if (params.costType == 1) {
        arr = JSON.parse(JSON.stringify(this.initColumns));
      } else {
        arr = JSON.parse(JSON.stringify(this.initCPDColumns));
      }
      const seriesList = JSON.parse(JSON.stringify(this.seriesList));
      // 有计划或推广组或创意，就需要显示计费类型
      // const isShowCostType = this.checkSeries.find((i) => {
      //   return ["isTaskShow", "isPloyShow", "isIdeaShow"].includes(i);
      // });
      // if (isShowCostType) {
      //   arr.unshift({ slot: "costType", label: "计费类型" });
      // }
      seriesList.reverse().forEach((a) => {
        if (this.checkSeries.find((b) => b === a.key)) {
          arr.unshift(a.showSeries);
        }
      });

      if (this.searchForm.reportType === 2) {
        arr.unshift({ slot: "pt", label: "日期" });
      }
      if (this.searchForm.reportType === 3) {
        arr.unshift({ slot: "pt", label: "小时" });
      }
      // if (params.isAppShow ===1) {
      //   arr.unshift({ key: "appId", label: "应用ID" });
      // }
      this.columns = arr;
    },
    async getReportStatistics(args) {
      //统计接口 过滤统计图表 header统计数据
      let params = this.filterParams();
      params = {
        ...params,
        ...args,
      };
      if (params.reportType == 3 && (!params.endTime || !params.startTime)) {
        this.$message.error(`按照小时格式查询时需选择时间段`);
        return;
      }
      const res = await getTaskReportSum(params);
      if (res.code !== 0) return;
      let data = res.data;
      let { allStatistic = {}, list } = data;
      this.taskHeaderList.forEach((a) => {
        a.value = allStatistic[a.key] || 0;
      });
      let { xAxis, series } = this.chartOptions;
      xAxis.data = [];
      series.forEach((a) => {
        a.data = [];
      });
      if (!list) list = [];
      list.forEach((a) => {
        xAxis.data.push(
          params.reportType == 3 ? a.pt + "_" + a.hourKey + "点" : a.pt
        );
        series.forEach((b) => {
          b.data.push(a[b.dataKey]);
        });
      });
      this.chartOptions.xAxis = xAxis;
      this.chartOptions.series = series;
      this.initCharts();
    },
    getDateStr(now) {
      //获取年月日
      var year = now.getFullYear(); // 年
      var month = now.getMonth() + 1; // 月
      var day = now.getDate(); // 日
      if (day < 10) {
        day = "0" + day;
      }

      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month + "-" + day;
    },
    changeDataTime(val) {
      let date = this[this.dataTimes.find((a) => a.value === val).fn]();
      this.searchForm.date = date;
    },
    nowWeek() {
      //本周
      var now = new Date();
      var nowDayOfWeek = now.getDay() == 0 ? 6 : now.getDay() - 1;
      var startTime = this.getDateStr(
        new Date(now.getTime() - nowDayOfWeek * 24 * 60 * 60 * 1000)
      );
      var endTime = this.getDateStr(now);
      return [startTime, endTime];
    },
    nowMonth() {
      //本月
      var now = new Date();
      var nowDay = now.getDate() - 1;
      var startTime = this.getDateStr(
        new Date(now.getTime() - nowDay * 24 * 60 * 60 * 1000)
      );
      var endTime = this.getDateStr(now);
      return [startTime, endTime];
    },
    lastMonth() {
      //上月
      const nowDays = new Date();
      let year = nowDays.getFullYear();
      let month = nowDays.getMonth();
      //new Date()第3个参数设置为0时， new Date()会返回上一个月的最后一天
      let days = new Date(year, month, 0).getDate();
      if (month == 0) {
        month = 12;
        year = year - 1;
      }
      if (month < 10) {
        month = "0" + month;
      }
      const startDate = `${year}-${month}-01`;
      const endDate = `${year}-${month}-${days}`;
      return [startDate, endDate];
    },
    filterParams() {
      let params = { ...this.searchForm };
      params.startTime = params.date && params.date[0];
      params.endTime = params.date && params.date[1];
      params.userIds = params.userIdList.filter((a) => a !== ""); //投放者ID
      params.countryList = params.countryCode.filter((a) => a !== ""); //国家
      params.appIdList = params.appIdList.filter((a) => a !== ""); //应用ID
      params.planIdList = params.taskIdList.filter((a) => a !== ""); //推广计划
      params.groupIdList = params.ployIdList.filter((a) => a !== ""); //推广组
      params.ideaIdList = params.ideaIdList.filter((a) => a !== ""); //推广创意
      params.recomType = params.recomType.filter((a) => a !== "");
      params.manageIdList = params.businessUserId.filter((a) => a !== ""); //商务

      if (params.manageIdList.length == 1 && params.manageIdList[0] === "")
        params.manageIdList = [];
      if (params.recomType.length == 1 && params.recomType[0] === "")
        params.recomType = [];
      if (params.userIds.length == 1 && params.userIds[0] === "")
        params.userIds = [];
      if (params.countryList.length == 1 && params.countryList[0] === "")
        params.countryList = [];
      if (params.planIdList.length == 1 && params.planIdList[0] === "")
        params.planIdList = [];
      if (params.groupIdList.length == 1 && params.groupIdList[0] === "")
        params.groupIdList = [];
      if (params.appIdList.length == 1 && params.appIdList[0] === "")
        params.appIdList = [];
      if (params.ideaIdList.length == 1 && params.ideaIdList[0] === "")
        params.ideaIdList = [];

      delete params.date;
      delete params.dataTime;

      delete params.countryCode;
      delete params.ployIdList;
      delete params.recomTypeList;
      delete params.userIdList;
      delete params.taskIdList;
      delete params.businessUserId;

      return params;
    },
    searchFn() {
      // if (this.searchForm.reportType == 3) {
      //   if (this.searchForm.date.length > 0) {
      //     let dayNum = getDaysBetweenDay(
      //       this.searchForm.date[0],
      //       this.searchForm.date[1]
      //     );
      //     if (dayNum > 2) {
      //       this.$message.error("日期范围不能大于3天");
      //       return;
      //     }
      //   } else {
      //     this.$message.error("请选择日期范围");
      //     return;
      //   }
      // }
      this.getReportList();
      if (this.searchForm.costType == 1) {
        this.isShowCPAEcharts = true;
        this.getReportStatistics();
      } else {
        //cpa不显示上面图表
        this.isShowCPAEcharts = false;
      }
    },
    refresh() {
      this.searchForm = this.$options.data().searchForm;
      this.searchForm.date = this.nowWeek();
      this.checkSeries = [];
      this.init();
    },
    disabledDate(date) {
      //时间禁用
      let nowDate = new Date().getTime();
      if (date.getTime() < nowDate) {
        return false;
      } else {
        return true;
      }
    },
    async changeDate(val) {
      //切换时间 联动时间类型
      this.searchForm.dataTime = [];
      this.dataTimes.forEach((a) => {
        let fnDate = this[a.fn]();
        if (val && fnDate[0] === val[0] && fnDate[1] === val[1]) {
          this.searchForm.dataTime = a.value;
        }
      });
      await this.initployAndIdea();
    },
    collaborateType(val) {
      let str = this.collaborateList.find((item) => item.value == val);
      if (str) {
        return str.label;
      } else {
        return val;
      }
    },
    // 获取国家地区列表
    async getLanguageList() {
      const res = await cmsCountryAll();
      this.languageList = [
        {
          countryName: "全部地区",
          countryCode: "",
        },
      ].concat(res.data);
    },
    changeSource(val) {
      if (!val) {
        this.recomList = this.recomLabelTypeList2;
        this.searchForm.recomTypeList = [1];
      } else if (val == 1) {
        this.recomList = this.recomLabelTypeList;
        this.searchForm.recomTypeList = [1];
      } else {
        this.recomList = this.recomLabelTypeList1;
        this.searchForm.recomTypeList = [101];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../common/css/common.scss";
.report {
  height: auto;
  padding: 0 16px;
  .labelHdader {
    @extend .fz16;
    padding-right: 64px;
    line-height: 72px;
    margin-bottom: 6px;
    .headerOperation {
      .Oper {
        @extend .pointer;
        > img {
          @extend .inlinebox;
          @include rect(18px);
          margin-right: 6px;
        }
        + .Oper {
          margin-left: 42px;
        }
      }
    }
  }
  .searchForm {
    line-height: 32px;
    margin-bottom: 40px;
    .el-form-item {
      margin-bottom: 0;
      height: 32px;
    }
    /deep/.dataTime,
    /deep/.dataType {
      width: 98px;
    }
    /deep/.date {
      width: 296px;
    }
    /deep/ .app,
    .task,
    .formItem {
      width: 170px;
    }
    /deep/.el-form-item + .el-form-item {
      margin-left: 16px;
    }
    /deep/.el-select__tags {
      .el-tag {
        max-width: 70%;
      }
    }
  }
  .taskStatistics {
    height: 100px;
    margin-bottom: 61px;
    .taskBox {
      // .task:first-child {
      //   width: 205px;
      //   text-align: left;
      //   flex: none;
      // }
      .task + .task {
        border-left: 1px solid #e4e4e4;
      }
    }
    .moneyTxt {
      margin-bottom: 10px;
    }
  }
  .chart {
    height: 430px;
    @include mb(50);
  }
  .seriesBox {
    height: 40px;
    margin-bottom: 20px;
  }
  .pageTable {
    margin-bottom: 100px;
  }
}
</style>
